<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        账号管理
      </template>
      <template #input>
        <a-input placeholder="请输入关键字" v-model="queryParams.keyword"  allow-clear v-hasPermi="['sysuser:list:query']"/>
        <a-button type="primary" class="btn" @click="onSearch" v-hasPermi="['sysuser:list:query']">搜索</a-button>
        <a-button class="all_boder_btn" @click="onCreate(1)" v-hasPermi="['sysuser:info:add']">新建账号</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
      class="table-template"
      :rowKey="item=>item.userId"
      :columns="columns"
      :loading="loading"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total:total,
        current:queryParams.pageNum,  
        defaultPageSize:queryParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
          </div>
        </template>
        <template slot="status" slot-scope="text">
          <span class="state_all" :class="text==1?'state_color_green':text==2?'state_color_red':text==-1?'state_color_yellow':''">
            {{text==1?'已启用':text==2?'已禁用':text==-1?'已失效':''}}
          </span>
        </template>
      <template slot="operation" slot-scope="text">
        <div class="btn_router_link">
          <router-link to @click.native="onCreate(2,text.userId)" v-hasPermi="['sysuser:privileges:edit']">修改权限</router-link>
          <span v-hasPermi="['sysuser:info:updatestatus']">|</span>
          <router-link v-hasPermi="['sysuser:info:updatestatus']" to @click.native="onStop(text)">{{text.status==1?'禁用':'启用'}}</router-link>
          <span v-hasPermi="['sysuser:info:delete']">|</span>
          <router-link v-hasPermi="['sysuser:info:delete']" to @click.native="onDel(text.userId)">删除</router-link>
          <span v-hasPermi="['sysuser:password:update']">|</span>
          <router-link v-hasPermi="['sysuser:password:update']" to @click.native="isResetPswShow=true,user=text.userId">重置密码</router-link>
        </div>
      </template>
    </a-table>
    </div>
    <!-- 重置密码 -->
    <a-modal v-model="isResetPswShow" align="center" @ok="onResetPsw()" title="重置密码" width="360px">
      <span>是否确认重置该账号密码</span>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "mobile"
  },
  {
    title: "用户名",
    align:'center',
    dataIndex: "userName"
  },
  // {
  //   title: "创建账号",
  //   align:'center',
  //   dataIndex: "code4"
  // },
  // {
  //   title: "最后一次登录时间",
  //   align:'center',
  //   dataIndex: "code5"
  // },
  {
    title: "状态",
    align:'center',
    dataIndex: "status",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "操作",
    align:'center',
    width: "240px",
    scopedSlots: { customRender: "operation" }
  }
];
import HeaderBox from '@/components/HeaderBox.vue'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns, // 表头
      tableData:[], // 列表数据
      loading:false, // 列表加载
      total: 0, // 总数量
      isResetPswShow:false, // 重置密码
      user:'',
      queryParams: {
        pageNum: 1, //页码
        pageSize: 10, // 页数
        keyword:''
      }
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getManageList()
    },
    // 创建账号
    onCreate(operation,id){
      this.$router.push({ path: "/admin/UserManage/CreateUser", query: { operation:operation,id: id } })
    },
    // 高级检索
    onSearch(){
      this.queryParams.pageNum = 1
      this.getManageList()
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/user/info/manage/list",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    // 删除
    onDel(id) {
      let _this = this
      this.$confirm({
        title: '确定删除该数据吗?',
        okText: '确认',
        onOk() {
          _this.loading = true
          _this.$ajax({
            url: '/hxclass-management/user/info/del?userId=' + id,
            method: "DELETE",
          }).then((res) => {
            _this.loading = false
            if (res.code == 200 && res.success) {
              _this.$message.success('删除成功');
              _this.getManageList()
            }
          })
        },
        onCancel() {},
      });
    },
    // 控制 启用/禁用
    onStop(e){
      this.loading = true
      this.$ajax({
        method: 'put',
        url:"/hxclass-management/user/info/manage/deactivate",
        params: {
          id: e.userId,
          type: e.status == 1 ? 2 : 1
        }
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.getManageList()
        }
      })
    },
    // 密码重置
    onResetPsw(){
      this.loading = true
      this.$ajax({
        method: 'put',
        url:"/hxclass-management/user/info/manage/update/password?userId=" + this.user,
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.isResetPswShow = false
          this.$message.success(res.message);
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getManageList()
   },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
</style>
